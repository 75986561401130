<template>
  <Animated name="pulse" appear duration="1200">
    <div class="">
      <sequential-entrance fromTop>
        <SellerHeader headerText="Reporte de Recibos" :superAdmin="true" />
      </sequential-entrance>

      <div class="mt-5 p-3">
        <div>
          <v-row class="mt-0">
            <v-col cols="12" class="pt-0">
              <v-text-field
                v-model="filterByClient"
                @keyup="getAllPdfTicket()"
                label="Filtra por cliente"
                rounded
                color="#00B8D9"
                background-color="#F4F5F7"
                class="app-input__text-field"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="12" class="pt-0">
              <v-select
                item-text="name"
                item-value="id"
                v-model="filterByEvent"
                :items="events"
                @change="getAllPdfTicket()"
                label="Filtra por eventos"
                rounded
                color="#00B8D9"
                background-color="#F4F5F7"
                class="app-input__select pt-0"
              ></v-select>
            </v-col>
          </v-row>

          <v-row class="mt-0">
            <v-col cols="12" class="pt-0">
              <v-select
                item-text="name"
                item-value="value"
                v-model="filterByPayment"
                :items="paymentMethods"
                @change="getAllPdfTicket()"
                label="Filtra por Metodo de pago"
                rounded
                color="#00B8D9"
                background-color="#F4F5F7"
                class="app-input__select pt-0"
              ></v-select>
            </v-col>
          </v-row>
        </div>

        <template v-if="!loading">
          <v-data-table
            :headers="headers"
            :items="tickets"
            :options.sync="optionsTable"
            :server-items-length="totalTickets"
            :loading="loading"
            :page.sync="page"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :items-per-page="itemsPerPage"
            @page-count="pageCount = $event"
            hide-default-footer
            class="elevation-1"
          >
            <template v-slot:item.pdf="{ item }">
              <a target="_blank" :href="item.pdf" v-if="item.pdf">
                <span class="mdi mdi-file-pdf-box"></span>
              </a>
              <b v-if="!item.pdf">
                <span class="mdi mdi-sim-off-outline"></span>
              </b>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :length="pageCount"
              @input="handlePageChange"
            ></v-pagination>
          </div>
        </template>

        <!-- Skeleton Loader -->
        <v-container v-if="loading">
          <v-row
            v-for="(skeleton, index) in skeletonCount"
            class="align-center mb-5"
            :key="index"
          >
            <SkeletonTemplate />
          </v-row>
        </v-container>
      </div>
    </div>
  </Animated>
</template>

<script>
import { Vue } from 'vue-property-decorator'
import {
  getAllPdfTicket,
  getAllEventsSelectSuperAdmin,
  getAllBusiness,
} from '@/services/seller.service'
import SellerHeader from '@/components/Seller/SellerHeader.vue'
import SkeletonTemplate from '@/components/Seller/SkeletonTemplate.vue'
import { ErrorMessages } from '@/helpers/constants'

export default {
  data() {
    return {
      headers: [
        {
          text: 'Fecha de creacion',
          align: 'start',
          sortable: true,
          value: 'created_at',
        },
        {
          text: 'Evento',
          sortable: true,
          value: 'event',
        },
        {
          text: 'Asistente',
          value: 'assistant',
        },
        {
          text: 'DNI',
          value: 'dni',
        },
        {
          text: 'Metodo de pago',
          value: 'payment_method',
        },
        {
          text: 'Nro de Operacion',
          value: 'operation_number',
        },
        {
          text: 'Monto',
          value: 'amount',
        },
        {
          text: 'PDF',
          sortable: false,
          value: 'pdf',
        },
      ],
      totalTickets: 1000,
      optionsTable: {},
      user: null,
      loading: false,
      change: null,
      filterByClient: '',
      filterByEvent: 0,
      filterByPayment: '',
      business: null,
      skeletonCount: [0, 1, 2],
      events: [],
      tickets: [],
      page: 1,
      itemsPerPage: 15,
      pageCount: 0,
      sortBy: 'created_at',
      sortDesc: 'desc',
      paymentMethods: [
        {
          name: 'MCP',
          value: 'mcp',
        },
        {
          name: 'Mercado libre',
          value: 'mp',
        },
      ],
    }
  },
  watch: {
    sortBy(newSortBy) {
      // Se llama cuando cambia la columna de ordenación

      console.log('Columna de ordenación cambiada:', newSortBy)
      this.sortBy = newSortBy

      this.getAllPdfTicket()
    },
    sortDesc(newSortDesc) {
      console.log('Dirección de ordenación cambiada:', newSortDesc)
      this.sortDesc = newSortDesc

      console.log(this.sortDesc)

      this.getAllPdfTicket()
    },
  },
  computed: {},
  methods: {
    async getAllEvents() {
      try {
        const { data } = await getAllEventsSelectSuperAdmin()
        this.events = data.data
      } catch (error) {
        Vue.$toast.error(ErrorMessages.COMMON)
      }
    },
    async getAllBusiness() {
      const { data } = await getAllBusiness()
      const all = { id: '', name: 'Todos' }
      data.data.push(all)
      this.business = data.data
    },

    handlePageChange(newPage) {
      this.page = newPage
      this.getAllPdfTicket()
    },

    async getAllPdfTicket() {
      try {
        this.loading = true
        console.log(
          this.filterByEvent,
          this.filterByClient,
          this.filterByPayment,
          this.sortBy,
          this.sortDesc
        )

        const { data } = await getAllPdfTicket(
          this.page,
          this.filterByEvent ? this.filterByEvent : 0,
          this.filterByClient ? this.filterByClient : '',
          this.filterByPayment ? this.filterByPayment : '',
          this.sortBy != undefined ? this.sortBy : 'created_at',
          this.sortDesc != undefined ? this.sortDesc : true
        )

        //this.totalTickets = meta.total;

        this.tickets = data.data
        this.loading = false
        console.log(data)
      } catch (e) {
        console.error(e)
      }
    },
  },
  mounted() {
    this.getAllPdfTicket()
    this.getAllEvents()
  },
  components: {
    SellerHeader,
    SkeletonTemplate,
  },
}
</script>

<style lang="scss" scope></style>
